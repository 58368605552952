<template>
  <form @submit.prevent.stop="save" class="row q-gutter-lg" v-if="formFields">
    <div class="col">
      <div>
        <h6 class="title-h6">{{$t('processes.linked_wallets')}}</h6>
        <div class="row q-col-gutter-md q-mb-md">
          <add-a-wallet v-if="currentOuvertureGiProcess && currentOuvertureGiProcess.wallets" @walletsUpdated="updateWallet" v-model="currentOuvertureGiProcess.wallets" :wallets="currentOuvertureGiProcess.wallets" class="col"/>
          <add-a-wallet v-else @walletsUpdated="updateWallet" class="col"/>
        </div>
        <q-card flat bordered class="q-pa-sm q-mb-md">
          <q-card-section class="justify-center">
            <div class="row">
              <div class="col">
                <q-item-label class="q-pt-sm q-pl-sm">{{$t('processes.fields.commentaires.label')}}</q-item-label>
                <base-input-text class="q-px-sm q-pb-none row" placeholder="" label="" v-bind="formInputProps('commentaire')" :color="color"
                  v-model="formData.commentaire"  />
              </div>
            </div>
          </q-card-section>
        </q-card>
        <q-card flat bordered class="q-pa-sm q-mb-md collapsible-div">
          <q-card-section class="justify-center">
            <div class="row q-gutter-sm">
              <base-input-date class="col" v-bind="formInputProps('date_ouverture')" :color="color"
                v-model="formData.date_ouverture" ref="label" />
              <base-input-date class="col" v-bind="formInputProps('date_previsionnelle_fermeture')" :color="color"
                v-model="formData.date_previsionnelle_fermeture" ref="label" />
            </div>
            <div class="row">
              <div class="col-6">
                <base-input-date class="" v-bind="formInputProps('date_envoi_du_questionnaire')" :color="color"
                  v-model="formData.date_envoi_du_questionnaire" ref="label" />
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="bloc-item q-mb-sm q-mt-md">
        <h6 class="title-h6 process-color">{{$t('processes.dossier')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <q-card flat bordered class="q-pa-sm q-mb-md collapsible-div">
          <q-card-section class="justify-center">
            <div class="row q-gutter-sm">
              <base-input-date class="col" v-bind="formInputProps('date_completude_du_dossier')" :color="color"
                v-model="formData.date_completude_du_dossier" ref="label" />
              <base-input-date class="col" v-bind="formInputProps('date_demande_creation_etablissement_Helium')" :color="color"
                v-model="formData.date_demande_creation_etablissement_Helium" ref="label" />
            </div>
            <div class="row q-gutter-sm">
              <base-input-date class="col" v-bind="formInputProps('date_demande_immatriculation_GI')" :color="color"
                v-model="formData.date_demande_immatriculation_GI" ref="label" />
              <base-input-date class="col" v-bind="formInputProps('date_immatriculation_GI')" :color="color"
                v-model="formData.date_immatriculation_GI" ref="label" />
            </div>
            <div class="row q-gutter-sm">
              <base-input-date class="col" v-bind="formInputProps('date_avis_aux_services')" :color="color"
                v-model="formData.date_avis_aux_services" ref="label" />
              <base-input-date class="col" v-bind="formInputProps('date_MAJ_ICX')" :color="color"
                v-model="formData.date_MAJ_ICX" ref="label" />
            </div>
            <div class="row q-gutter-sm">
              <base-input-date class="col" v-bind="formInputProps('date_mise_en_place_contrat_affranchigo')" :color="color"
                v-model="formData.date_mise_en_place_contrat_affranchigo" ref="label" />
              <base-input-date class="col" v-bind="formInputProps('date_procuration_postale')" :color="color"
                v-model="formData.date_procuration_postale" ref="label" />
            </div>
            <div class="row q-gutter-sm">
              <base-input-date class="col" v-bind="formInputProps('date_signature_convention_de_courtage')" :color="color"
                v-model="formData.date_signature_convention_de_courtage" ref="label" />
              <base-input-text class="col" v-bind="formInputProps('IBAN_CBC')" :color="color"
                v-model="formData.IBAN_CBC" ref="label" />
            </div>
            <div class="row q-gutter-sm">
              <base-input-date class="col" v-bind="formInputProps('date_etablissment_convention_assuralpes')" :color="color"
                v-model="formData.date_etablissment_convention_assuralpes" ref="label" />
              <base-input-date class="col" v-bind="formInputProps('date_demande_emailing_info_clientele')" :color="color"
                v-model="formData.date_demande_emailing_info_clientele" ref="label" />
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="bloc-item q-mb-sm q-mt-md">
        <h6 class="title-h6 process-color">{{$t('processes.dateRepriseEffectiveDesAbos')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="collapsible-div">
          <q-card flat bordered class="q-pa-sm q-mb-md">
            <q-card-section class="justify-center">
              <div class="row q-gutter-sm">
                <base-input-date class="col" v-bind="formInputProps('date_reprise_gaz')" :color="color"
                  v-model="formData.date_reprise_gaz" ref="label" />
                <base-input-date class="col" v-bind="formInputProps('date_reprise_eau')" :color="color"
                  v-model="formData.date_reprise_eau" ref="label" />
              </div>
              <div class="row q-gutter-sm">
                <base-input-date class="col" v-bind="formInputProps('date_reprise_electricite')" :color="color"
                  v-model="formData.date_reprise_electricite" ref="label" />
                <base-input-date class="col" v-bind="formInputProps('date_reprise_nettoyage')" :color="color"
                  v-model="formData.date_reprise_nettoyage" ref="label" />
              </div>
              <div class="row q-gutter-sm">
                <div class="col q-mt-md">
                  <q-item-label class="">{{$t('processes.fields.portabilite_telephone.label')}}</q-item-label>
                  <q-toggle class="" v-model="formData.portabilite_telephone"
                    :color="color"
                  />
                </div>
                <base-input-date class="col" v-bind="formInputProps('date_reprise_telephone')" :color="color"
                  v-model="formData.date_reprise_telephone" ref="label" />
              </div>
            </q-card-section>
          </q-card>
          <q-card flat bordered class="q-pa-sm q-mb-md">
            <q-card-section class="justify-center">
              <div class="row q-gutter-sm">
                <base-input-select :options="booleanOption" class="col" v-bind="formInputProps('conformite_electrique')" :color="color"
                  v-model="formData.conformite_electrique" ref="label" />
                <base-input-select :options="booleanOption" class="col" v-bind="formInputProps('conformite_extincteur')" :color="color"
                  v-model="formData.conformite_extincteur" ref="label" />
              </div>
            </q-card-section>
          </q-card>
        </div>
      </div>
      <div class="bloc-item q-mb-sm q-mt-md">
        <h6 class="title-h6 process-color">{{$t('processes.personnel')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="collapsible-div">
          <q-card flat bordered class="q-pa-sm q-mb-md">
            <q-card-section class="justify-center">
              <div class="row q-gutter-sm">
                <div class="col q-mt-md">
                  <q-item-label class="">{{$t('processes.fields.registre_personnel_recupere.label')}}</q-item-label>
                  <q-toggle class="" v-model="formData.registre_personnel_recupere" ref="is_red"
                    :color="color"
                  />
                </div>
                <base-input-date class="col" v-bind="formInputProps('date_transmission_registre_personnel')" :color="color"
                  v-model="formData.date_transmission_registre_personnel" ref="label" />
              </div>
              <div class="row">
                <div class="col-6">
                  <base-input-date class="col" v-bind="formInputProps('date_mail_information_personnel_agence')" :color="color"
                    v-model="formData.date_mail_information_personnel_agence" ref="label" />
                </div>
              </div>
            </q-card-section>
          </q-card>
          <q-card flat bordered class="q-pa-sm q-mb-md">
            <q-card-section class="justify-center">
              <div class="row q-gutter-sm">
                <base-input-date class="col" v-bind="formInputProps('date_envoi_courrier_organismes_sociaux')" :color="color"
                  v-model="formData.date_envoi_courrier_organismes_sociaux" ref="label" />
                <base-input-date class="col" v-bind="formInputProps('date_mail_GGvie')" :color="color"
                  v-model="formData.date_mail_GGvie" ref="label" />
              </div>
              <div class="row q-gutter-sm">
                <base-input-date class="col" v-bind="formInputProps('date_paiement_passif_social')" :color="color"
                  v-model="formData.date_paiement_passif_social" ref="label" />
                <base-input-text class="col" v-bind="formInputProps('montant_passif_social')" :color="color"
                  v-model="formData.montant_passif_social" ref="label" />
              </div>
              <div class="row">
                <div class="col-6">
                  <base-input-date v-bind="formInputProps('date_demande_GGVIE_souscription_sante')" :color="color"
                    v-model="formData.date_demande_GGVIE_souscription_sante" ref="label" />
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>
      </div>
      <div class="bloc-item q-mb-sm q-mt-md">
        <h6 class="title-h6">{{$t('processes.salaries')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="collapsible-div">
          <q-card v-for="salarie in relatedSalaries" :key="salarie.id" flat bordered class="q-pa-sm q-mb-sm">
            <q-card-section class="justify-center">
              <div class="row q-gutter-sm">
                <base-input-select :options="booleanOption" type="select" :label="$t('processes.fields.avenant_contrat_travail_necessaire.label')" class="col q-mt-none" :color="color"
                  v-model="salarie.avenant_contrat_travail_necessaire" ref="label" />
                <base-input-date class="col" :label="$t('processes.fields.date_regularisation_avenant.label')" :color="color"
                  v-model="salarie.date_regularisation_avenant" ref="label" />
              </div>
              <div class="row q-gutter-sm">
                <base-input-text class="col" :label="$t('processes.fields.nom_prenom.label')" :color="color"
                    v-model="salarie.nom_prenom" />
                <base-input-date class="col" :label="$t('processes.fields.date_dernier_entretien.label')" :color="color"
                    v-model="salarie.date_dernier_entretien" />
              </div>
              <div class="row q-gutter-sm">
                <base-input-date class="col" :label="$t('processes.fields.date_envoi_lettre_reprise.label')" :color="color"
                    v-model="salarie.date_envoi_lettre_reprise" />
                <base-input-date class="col" :label="$t('processes.fields.date_derniere_visite_medecine_travail.label')" :color="color"
                    v-model="salarie.date_derniere_visite_medecine_travail" />
              </div>
              <div class="row justify-end q-mt-md q-gutter-x-sm">
                <div class="col col-3 q-py-sm">
                  <q-btn :color="color" class="full-width" size="sm" outline @click="deleteSalarie(salarie)" :disabled="!isUpdate">{{$t('form.delete')}}</q-btn>
                </div>
                <div class="col col-3 q-py-sm">
                  <q-btn class="full-width" color="process" size="sm" @click="saveSalarie(salarie)" :disabled="!isUpdate">{{$t('processes.save')}}</q-btn>
                </div>
              </div>
            </q-card-section>
          </q-card>
          <q-card flat bordered class="q-pa-sm q-mt-md">
            <q-card-section class="justify-center">
              <div class="row q-gutter-sm">
                <base-input-select :options="booleanOption" :label="$t('processes.fields.avenant_contrat_travail_necessaire.label')" class="col q-mt-none" :color="color"
                  v-model="salarie.avenant_contrat_travail_necessaire" ref="label" />
                <base-input-date class="col" :label="$t('processes.fields.date_regularisation_avenant.label')" :color="color"
                  v-model="salarie.date_regularisation_avenant" ref="label" />
              </div>
              <div class="row q-gutter-sm">
                <base-input-text class="col" :label="$t('processes.fields.nom_prenom.label')" :color="color"
                    value="" v-model="salarie.nom_prenom" />
                <base-input-date class="col" :label="$t('processes.fields.date_dernier_entretien.label')" :color="color"
                    value="" v-model="salarie.date_dernier_entretien" />
              </div>
              <div class="row q-gutter-sm">
                <base-input-date class="col" :label="$t('processes.fields.date_envoi_lettre_reprise.label')" :color="color"
                    value="" v-model="salarie.date_envoi_lettre_reprise" />
                <base-input-date class="col" :label="$t('processes.fields.date_derniere_visite_medecine_travail.label')" :color="color"
                    value="" v-model="salarie.date_derniere_visite_medecine_travail" />
              </div>
              <div class="row q-mt-md justify-end">
                <div class="col col-3 q-py-sm q-px-md">
                  <q-btn class="full-width" color="process" size="sm" @click="saveSalarie()" :disabled="!isUpdate">{{$t('processes.enregistrer')}}</q-btn>
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>
      </div>
      <div class="bloc-item q-mb-sm q-mt-md">
        <h6 class="title-h6 process-color">{{$t('processes.bail')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <q-card flat bordered class="q-pa-sm q-mb-md collapsible-div">
          <q-card-section class="justify-center">
            <div class="row q-gutter-sm">
              <div class="col">
                <base-input-date class="col" v-bind="formInputProps('date_signature_bail')" :color="color"
                  v-model="formData.date_signature_bail" ref="label" />
              </div>
              <div class="col">
                <base-input-date class="col" v-bind="formInputProps('date_signature_mise_a_disposition')" :color="color"
                  v-model="formData.date_signature_mise_a_disposition" ref="label" />
              </div>
            </div>
            <div class="row q-gutter-sm">
              <base-input-select class="col" v-bind="formInputProps('periodicite')" :color="color"
                  v-model="formData.periodicite" ref="label" />
              <base-input-text class="col" v-bind="formInputProps('montant_du_loyer')" :color="color"
                v-model="formData.montant_du_loyer" ref="label" />
            </div>
            <div class="row q-gutter-sm">
              <base-input-text class="col" v-bind="formInputProps('montant_de_la_garantie')" :color="color"
                v-model="formData.montant_de_la_garantie" ref="label" />
              <base-input-date class="col" v-bind="formInputProps('date_de_fin_de_bail_possible')" :color="color"
                v-model="formData.date_de_fin_de_bail_possible" ref="label" />
            </div>
            <div class="row q-gutter-sm">
              <base-input-select class="col" v-bind="formInputProps('type_de_bail')" :color="color"
                v-model="formData.type_de_bail" ref="label" />
              <base-input-text class="col" v-bind="formInputProps('preavis_impose')" :color="color"
                v-model="formData.preavis_impose" ref="label" />
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="bloc-item q-mb-sm q-mt-md">
        <h6 class="title-h6 process-color">{{$t('processes.localEtMobilier')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <q-card flat bordered class="q-pa-sm q-mb-md collapsible-div">
          <q-card-section class="justify-center">
            <div class="row q-gutter-sm">
              <base-input-text class="col" v-bind="formInputProps('etablissement_ou_transfert_bail')" :color="color"
                v-model="formData.etablissement_ou_transfert_bail" ref="label" />
              <base-input-date class="col" v-bind="formInputProps('date_changement_des_serrures')" :color="color"
                v-model="formData.date_changement_des_serrures" ref="label" />
            </div>
            <div class="row q-gutter-sm">
              <base-input-date class="col" v-bind="formInputProps('date_demande_etat_des_lieux')" :color="color"
                v-model="formData.date_demande_etat_des_lieux" ref="label" />
              <base-input-date class="col" v-bind="formInputProps('date_realisation_etat_des_lieux')" :color="color"
                v-model="formData.date_realisation_etat_des_lieux" ref="label" />
            </div>
            <div class="row q-gutter-sm">
              <base-input-date class="col" v-bind="formInputProps('date_demande_assurance')" :color="color"
                v-model="formData.date_demande_assurance" ref="label" />
              <base-input-date class="col" v-bind="formInputProps('date_reception_du_contrat_assurance')" :color="color"
                v-model="formData.date_reception_du_contrat_assurance" ref="label" />
            </div>
            <div class="row q-gutter-sm">
              <base-input-date class="col" v-bind="formInputProps('date_signature_du_contrat_de_location_de_mobilier')" :color="color"
                v-model="formData.date_signature_du_contrat_de_location_de_mobilier" ref="label" />
              <base-input-date class="col" v-bind="formInputProps('date_demande_signaletique')" :color="color"
                v-model="formData.date_demande_signaletique" ref="label" />
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="bloc-item q-mb-sm">
        <h6 class="title-h6 process-color">{{$t('processes.linked_suivi_signaletique')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="collapsible-div row">
          <div v-if="relatedSuiviSignaletique.length" class="row full-width q-col-gutter-md q-pt-sm">
            <div class="col-12" v-for="suiviSignalétique in relatedSuiviSignaletique" :key="suiviSignalétique.id">
              <base-card @menuClick="onMenuClick" class="col-12" :actions="suiviSignalétique.actions">
                <q-avatar slot='avatar' size="3rem" :color="color" text-color="white" icon="playlist_add_check" />
                <q-item-label @click="$router.push({ name: 'suivi-de-dossier-signaletique-form', params: { id: suiviSignalétique.id } })" class="cursor-pointer q-mb-md"><strong>{{$t('processes.suivi_signaletique')}}</strong></q-item-label>
                <div class="row">
                  <div class="col" v-for="obj in suiviSignalétique.wallets" :key="obj.id">
                    <q-item class="q-pa-none">
                      <q-item-section avatar>
                        <q-avatar icon="account_balance_wallet" size="2rem" color="reseau" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label caption>{{obj.NOMPTF}}</q-item-label>
                      </q-item-section>
                    </q-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col" v-for="obj in suiviSignalétique.agents" :key="obj.id">
                    <q-item class="q-pa-none">
                      <q-item-section avatar>
                        <q-avatar icon="accessibility" size="2rem" color="reseau" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label caption>{{obj.TITULAIRE}}</q-item-label>
                      </q-item-section>
                    </q-item>
                  </div>
                </div>
              </base-card>
            </div>
          </div>
          <div v-else class="col-6" style="height:80px">
            <base-add-button :label="$t('processes.create_process')" :disabled="!isUpdate" :color="color" @click="createLinkedProcess('signaletique')" />
          </div>
        </div>
      </div>
    </div>

    <div class="col col-2 q-gutter-y-sm">
      <q-list class="full-width">
        <q-item class="q-pa-none">
          <base-input-select v-bind="formInputProps('statut')" :color="color"
            v-model="formData.statut" ref="statut" />
      </q-item>
      </q-list>

      <div class="q-gutter-y-sm">
        <q-btn :color="color" class="full-width" size="sm" outline @click.native="destroy" :disabled="!isUpdate">{{$t('form.delete')}}</q-btn>
        <q-btn :color="color" class="full-width" size="sm" unelevated type="submit" :disabled="!hasChanged">{{$t('form.submit')}}</q-btn>
        <q-btn :color="color" class="full-width q-py-md" size="sm" @click="finishProcess">{{$t('form.finish')}}</q-btn>
      </div>

    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { BaseForm } from '../../../mixins'
import addAWallet from '../shared/addAWallet'

export default {
  mixins: [BaseForm],
  components: { addAWallet },
  props: {
    'form_model': { type: String, default: 'processes' },
    'action': { type: String, default: 'processes/saveProcesses' }
  },

  data () {
    return {
      filter: {},
      loading: false,
      isFormOpen: false,
      searchBoxActivated: false,
      isUpdate: this.$route.params.id,
      linkedAgent: null,
      // related salariés
      relatedSalaries: [],
      salarie: {
      },
      // Related processes
      relatedProcessLinked: [],

      booleanOption: [
        { value: true, label: 'Oui' },
        { value: false, label: 'Non' }
      ]
    }
  },

  computed: {
    ...mapGetters({
      color: 'pages/getColor',
      isAdmin: 'auth/isAdmin',
      currentOuvertureGiProcess: 'processes/getCurrentProcess',
      rowsPerPageOptions: 'config/getRowsPerPageOptions'
    }),
    relatedSuiviSignaletique: {
      get () {
        return this.relatedProcessLinked.length ? this.relatedProcessLinked.filter(process => process.type === 'signaletique') : []
      },
      set (newVal) {
        this.formData.related_objects = this.formData.related_objects.filter(relObj => relObj.model_type !== 'signaletique')
        this.formData.related_objects.push(...newVal.map(suiviSignaletique => ({ model_type: 'signaletique', model_id: suiviSignaletique.id })))
        this.relatedProcessLinked = this.relatedProcessLinked.filter(process => process.type !== 'signaletique')
        this.relatedProcessLinked.push(...newVal)
      }
    }
  },

  watch: {
    formFields: {
      handler (newVal, oldVal) {
        this.formData.model_type = 'ouvertureGi'
        if (!newVal || !this.currentOuvertureGiProcess) return

        this.formData = {
          ...this.formData,
          ...newVal.map(({ id, options, type, boolean }) => ({ id, options, type, boolean }))
            .reduce((acc, { id, options, type, boolean }) => ({
              ...acc,
              [id]: options
                ? options.find(({ value }) => value === this.currentOuvertureGiProcess[id])
                : type === 'select' && boolean
                  ? this.booleanOption.find(val => val.value === this.currentOuvertureGiProcess[id])
                  : this.currentOuvertureGiProcess[id]
            }), {})
        }

        this.formData.related_objects = []
        if (this.$route.params.id) {
          this.formData.id = this.$route.params.id

          // Set wallets data
          let walletsMapped = this.currentOuvertureGiProcess.wallets ? this.currentOuvertureGiProcess.wallets.map(wallet => ({
            model_type: 'wallet',
            model_id: wallet.id
          })) : []
          this.formData.related_objects.push(...walletsMapped)

          // Set salariés
          if (this.currentOuvertureGiProcess.salaries) {
            this.relatedSalaries = this.currentOuvertureGiProcess.salaries.map(salarie => ({
              id: salarie.id,
              avenant_contrat_travail_necessaire: this.booleanOption.find(val => val.value === salarie.avenant_contrat_travail_necessaire),
              date_regularisation_avenant: salarie.date_regularisation_avenant,
              nom_prenom: salarie.nom_prenom,
              date_dernier_entretien: salarie.date_dernier_entretien,
              date_envoi_lettre_reprise: salarie.date_envoi_lettre_reprise,
              date_derniere_visite_medecine_travail: salarie.date_derniere_visite_medecine_travail
            }))
            let salariesFormDataMapped = this.relatedSalaries.map(salarie => ({
              model_type: 'salarie',
              model_id: salarie.id
            }))
            this.formData.related_objects.push(...salariesFormDataMapped)
          }

          // Set process suivi signalétique lié
          if (this.currentOuvertureGiProcess.signaletiques) {
            let suiviSignaletiqueFormDataMapped = this.currentOuvertureGiProcess.signaletiques.map(suiviSignaletique => ({
              model_type: 'signaletique',
              model_id: suiviSignaletique.id
            }))
            this.formData.related_objects.push(...suiviSignaletiqueFormDataMapped)

            let suiviSignaletiqueLinkedMapped = this.currentOuvertureGiProcess.signaletiques.map(suiviSignaletique => ({
              type: 'signaletique',
              id: suiviSignaletique.id,
              wallets: suiviSignaletique.wallets,
              agents: suiviSignaletique.agents,
              actions: [
                { label: this.$t('processes.unlink_suivi_signaletique'), payload: { name: 'unlink-suivi-signaletique', params: { id: suiviSignaletique.id } } }
              ]
            }))
            this.relatedProcessLinked.push(...suiviSignaletiqueLinkedMapped)
          }
        }
      },
      immediate: true
    }
  },

  mounted () {
    this.$store.commit('pages/setBreadcrumbs', [
      { label: this.$t('global.home'), to: { name: 'home' }, icon: 'home' },
      { label: this.$t('processes.processAgence'), to: { name: '' } },
      { label: this.$t('processes.title.ouverture_gi'), to: { name: 'ouverture-gi-en-cours' } },
      this.$route.params.id ? { label: `fiche #${this.$route.params.id}`, to: { name: 'ouverture-gi-form', params: { id: this.$route.params.id } } } : {}
    ])
    this.$store.commit('pages/setPageTitle', this.$t('processes.title.ouverture_gi'))

    this.$store.commit('pages/setTabs', [
      { name: 'ouverture-gi-form', label: 'Informations', to: { name: 'ouverture-gi-form' }, params: { id: this.$route.params.id } }
    ])
    if (this.$route.params.id) {
      this.formData.id = this.$route.params.id
    }
  },

  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    if (currentRoute.params.id) {
      store.dispatch('processes/fetchProcess', { id: currentRoute.params.id, type: 'ouvertureGi' }).then(() => {
        store.dispatch('processes/fetchFormFields', 'ouvertureGi')
      })
    } else {
      store.dispatch('processes/fetchFormFields', 'ouvertureGi')
    }
  },
  beforeDestroy () {
    this.$store.commit('processes/setCurrentProcess', null)
  },
  methods: {
    deleteSalarie (salarie) {
      this.$confirm(this.$t('processes.delete_process_confirm'), this.$t('form.delete')).onOk(() => {
        this.pending = true
        this.$store.dispatch('processes/deleteProcesses', { type: 'salarie', id: salarie.id }).then(() => {
          this.notifySuccess('processes.delete_success')
          this.relatedSalaries = this.relatedSalaries.filter(sala => sala.id !== salarie.id)
        }).catch(this.$notifyError)
          .finally(() => { this.pending = false })
      })
    },
    saveSalarie (salarie) {
      let params = {
        ...salarie || this.salarie,
        model_type: 'salarie',
        ouverture_gi_id: this.$route.params.id
      }

      params.avenant_contrat_travail_necessaire = typeof params.avenant_contrat_travail_necessaire === 'boolean' ? params.avenant_contrat_travail_necessaire : params.avenant_contrat_travail_necessaire.value

      this.$store.dispatch('processes/saveProcesses', params).then(data => {
        if (!salarie) {
          this.relatedSalaries.push({ id: data.id, ...this.salarie })
          this.salarie = {}
          this.formData.related_objects.push({
            model_type: 'salarie',
            model_id: data.id
          })
        }

        this.notifySuccess('processes.salarie_added')
      })
    },
    createLinkedProcess (process) {
      this.$store.dispatch('processes/createLinkedProcess', { linkedProcess_type: process, process_id: this.$route.params.id, process: 'ouvertureGi' }).then(linkedObj => {
        if (!this.formData.related_objects) this.formData.related_objects = []
        if (linkedObj.wallets) {
          this.relatedProcessLinked.push({
            id: linkedObj.id,
            type: process,
            wallets: linkedObj.wallets,
            agents: linkedObj.agents,
            actions: [
              { label: this.$t(`processes.unlink-suivi-signaletique`), payload: { name: `unlink-unlink-suivi-signaletique`, params: { id: linkedObj.id } } }
            ]
          })
          this.formData.related_objects.push({
            model_type: process,
            model_id: linkedObj.id
          })
        }
      })
    },
    finishProcess () {
      this.formData.statut = { label: 'TERMINE', value: 'TERMINE' }
      this.save()
    },
    toggleCollapsible (event) {
      let originalElement = event.target || event.srcElement || event.originalTarget
      if (originalElement.classList.contains('arrow')) {
        originalElement.parentElement.classList.toggle('rotate')
        originalElement.parentElement.nextSibling.classList.toggle('display-none')
      } else {
        originalElement.classList.toggle('rotate')
        originalElement.nextSibling.classList.toggle('display-none')
      }
    },
    onSave ({ id }) {
      this.isUpdate = true
      if (!this.$route.params.id) this.$router.push({ name: 'ouverture-gi-form', params: { id } })
    },
    copyCour () {
      const text = 'Ito ny texte'
      let el = document.createElement('textarea')
      el.value = text
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    },
    updateWallet (wallets) {
      console.log(wallets)
      let walletMapped = wallets.map(wallet => ({
        model_type: 'wallet',
        model_id: wallet.id
      }))

      if (!this.formData.related_objects) this.formData.related_objects = []
      // Remove all wallet in related object
      this.formData.related_objects = this.formData.related_objects.filter(related => related.model_type !== 'wallet')
      this.formData.related_objects.push(...walletMapped)
    },
    onSelectLinkedObj (obj) {
      console.log(obj)
      this.linkedAgent = {
        label: obj.value.label,
        id: obj.value.value,
        actions: [
          { label: this.$t('tasks.remove_linked_object'), payload: { name: 'model-detach', params: { id: obj.value.value } } }
        ]
      }
    },
    onMenuClick ({ name, params }) {
      this.hasChanged = true
      if (name === 'model-detach') {
        this.linkedAgent = null
        this.searchBoxActivated = false
      } else if (name === 'unlink-suivi-signaletique') {
        this.relatedSuiviSignaletique = this.relatedSuiviSignaletique.filter(signaletique => signaletique.id !== params.id)
      }
    },

    destroy () {
      this.$confirm(this.$t('processes.delete_process_confirm'), this.$t('form.delete')).onOk(() => {
        this.pending = true
        this.$store.dispatch('processes/deleteProcesses', { type: 'ouvertureGi', id: this.$route.params.id }).then(() => {
          this.notifySuccess('processes.delete_success')
          this.$router.go(-1)
        }).catch(this.$notifyError)
          .finally(() => { this.pending = false })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .process-color
    color $process
  .bloc-item
    position relative
  .toggler-collapse
    border 1px solid $process
    border-radius 100%
    width 20px
    height 20px
    position absolute
    right 0;
    top 7px;
    cursor pointer
  .arrow
    border-top 1px solid $process
    border-left 1px solid $process
    transform rotate(45deg)
    width 6px
    height 6px
    margin-left 6px
    margin-top 7px
  .display-none
    display none
  .rotate
    transform rotate(180deg)
</style>
